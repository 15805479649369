import styles from './ProductTiles.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { getToLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const ProductStats = ({
  stats,
}) => {

  const culture = useMemoizedCulture(stats);
  const formatDate = getToLocaleDate(culture, 'UTC');

  return (
    <div>
      {stats && (
        <table className={styles.productStats}>
          <tbody>
            <tr>
              <td><SimpleText textKey="Stats_TotalQuantityOrdered" /></td>
              <td>
                {stats.totalQuantityOrdered}
              </td>
            </tr>
            <tr>
              <td><SimpleText textKey="Stats_TotalTimesOrdered" /></td>
              <td>
                {stats.totalTimesOrdered}
              </td>
            </tr>
            <tr>
              <td><SimpleText textKey="Stats_MostRecentOrder" /></td>
              <td>
                {formatDate(stats.mostRecentOrder)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

ProductStats.propTypes = {
  stats: PropTypes.object,
};

export default ProductStats;

function useMemoizedCulture(stats) {
  const culture = useCultureName();
  return useMemo(() => culture, [stats]);
}