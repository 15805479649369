import styles from './ProductTiles.module.scss';
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import OrderBox from 'components/objects/productList/OrderBox';
import { ProductPrice, ProductThumbnail } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { useSelector } from 'react-redux';
import { useCanViewUom } from 'components/primitives/product';
import ProductStats from './ProductStats';
import { iEquals } from 'utils/helpers';
import UomTitle from 'components/primitives/product/UomTitle';

const ProductTile = ({ product, noImage, tilesPerView, productListPrice }) => {
  const ref = useRef(null);
  const { id, url, title, image, isRelatedProduct } = product;
  const { uom } = product;
  const getRouteData = routesBuilder.forProduct.bind(null, id);
  const { isAuthenticated } = useSelector(state => state.user);
  const multipleSizes = productListPrice && productListPrice.length > 1;
  let uomId = uom && uom.id;
  if (!uomId)
    uomId = product.uoms && product.uoms[0].id;

  const currentUom = uomId && product.uoms && product.uoms.find(u => iEquals(u.id, uomId)) || {};
  const canViewUom = currentUom && useCanViewUom();

  useEffect(() => {
    if (!tilesPerView)
      return;

    ref.current.style.width = 100 / tilesPerView + '%';

    return () => ref.current.style.width = '';
  }, [tilesPerView]);

  const { trackingSource } = useContext(ProductTrackingContext);

  const canOrder = useHasAbilities(AbilityTo.OrderProducts)[0];

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource }}>
      <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
        <div className={styles.tileTop}>
          <div className={styles.productImg}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} draggable="false" url={url} to={getRouteData}>
                  <ProductThumbnail draggable="false" src={image && (isRelatedProduct? image.medium : image.small)} noImageSrc={noImage} title={title} />
                </Link>
              )}
            </WithProductClickTracking>
          </div>
          <div className={styles.productDescription}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
                  {title}
                </Link>
              )}
            </WithProductClickTracking>
            {!product.hideSKU &&
              <div className={styles.productId}>
                <span className={styles.name}>
                  <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
                </span>
                <span className={styles.value}>{id}</span>
              </div>
            }
          </div>
        </div>
        {!isAuthenticated && product.price &&
          <div>
            <Link to={routesBuilder.forLogin} className={styles.signInText}>
              <SimpleText textKey="Link_SignIn" />
            </Link>
            <SimpleText textKey="SignInSeePriceLabel" />
          </div>
        }
        {isAuthenticated && multipleSizes &&
          <ProductStats stats={product.stats} />
        }
        {isAuthenticated &&
          <div className={styles.tileBottom}>
            <div className={styles.productAction}>
              {product.price && (
                <div className={styles.productPriceUomDescription}>
                  <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                  {canViewUom &&
                    <div className={styles.uomTitle}>
                      <SimpleText textKey="UnitSeparator" />
                      <UomTitle id={currentUom.id} description={currentUom.description} />
                    </div>
                  }
                </div>
              )}
            </div>
            {canOrder && product.isOrderable && multipleSizes && <OrderBox product={product} className={`${styles.orderBox} ${styles.orderBoxCustom}`} withoutBackTo />}
          </div>
        }
      </article>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
  }).isRequired,
  noImage: PropTypes.string,
  tilesPerView: PropTypes.number,
  productListPrice: PropTypes.array,
};

export default ProductTile;
